import React, {Fragment} from 'react';
import './footer.css'

import {compose} from "../../utils";

import insta from "../../images/instagram-circle-icon.webp";
import telega from "../../images/telegram_logo.png";
import logo from "../../images/logo_v2.png";
import GooglePlay from "../../images/GooglePlay.png"
import {withKozastoreService} from "../hoc";
import {connect} from "react-redux";
import {Link} from "react-router-dom";


class Footer extends React.Component {
    /*constructor(props) {
        super(props);

    }*/


    render() {

        let {language} = this.props;
        let phone = "+38-050-303-56-50";
        const textData = {
            En: {
                ourApp: 'Download our apps with AR',
                copyright: "Internet shop KOZA-store",
                contact: "Contacts",
                delivery: "Delivery and payment",
                privacy: "User agreement",
                onlineConstructor: "Online designer",
                opt: "Group orders"
            },
            Ua: {
                ourApp: 'Завантажуйте наші додатки з доповненою реальністю (AR)',
                copyright: "Інтернет магазин KOZA-store",
                contact: "Контакти",
                delivery: "Доставка та оплата",
                privacy: "Угода користувача",
                onlineConstructor: "Онлайн-конструктор",
                opt: "Гуртові замовлення"
            },
        };

        return (
            <Fragment>
                <br/>
                <div style={{backgroundColor: "orange"}}>
                    <div className="container">
                        <div className="row" style={{justifyContent: 'space-around'}}>
                            <div className={"col-2"}></div>
                            <div className="col-7"
                                 style={{display: 'flex', justifyContent: 'center', fontSize: "1rem"}}>
                                <a href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY"
                                   target="_blank" rel="noreferrer" style={{
                                    display: 'flex',
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    fontSize: "1.3em",
                                    fontWeight: "500",
                                    color: "purple"
                                }}>
                                    {textData[language]?.ourApp}
                                </a>
                            </div>
                            <div className="col-2">
                                <a href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY"
                                   target="_blank" rel="noreferrer"
                                   style={{justifyContent: 'center', paddingLeft: '0%', display: 'flex'}}>
                                    <img style={{width: '75%', paddingTop: "5%", paddingBottom: '5%', display: 'flex'}}
                                         src={GooglePlay} alt="google play"></img>
                                </a>
                            </div>
                            <div className={"col-1"}></div>
                        </div>
                    </div>

                </div>

                <div className={"row col-12"} style={{display: 'flex', justifyContent: 'center', fontSize: "1rem"}}>
                    <div className="container row">
                        <div className={"col-6"} style={{paddingLeft: 0}}>
                            <div className={"row col-12"} >
                                <div className={"col-6"} style={{paddingLeft: 0}}>
                                    <Link to={"/"}>
                                        <img style={{
                                            width: '10rem',
                                            paddingLeft: '0.1rem',
                                            paddingTop: "5%",
                                            paddingBottom: '0%',
                                            display: 'flex'
                                        }}
                                             src={logo} alt="google play"/>

                                    </Link>
                                    <div className={"footer-logo-text"}>настрій звичайних корисностей</div>
                                </div>
                                <div className={"col-6"}></div>
                            </div>
                            <div className={"col-12"} style={{color: "purple",paddingLeft: 0}}>
                                <div>
                                    &#169;{" "}2005-{new Date(Date.now()).getFullYear()}{" " + textData[language].copyright}
                                </div>
                            </div>
                        </div>
                        <div className={"col-6 row"}>
                            <div className={"col-5 rigth-textblock-container-footer"}>
                                <div>{phone}</div>
                                <div>{textData[language].contact}</div>
                                <div>{textData[language].del}</div>
                                <div>{textData[language].delivery}</div>
                                <Link to={"/privacy"}>{textData[language].privacy}</Link>
                            </div>
                            <div className={"col-6 rigth-textblock-container-footer"}>
                                <div>{textData[language].onlineConstructor}</div>
                                <div>{textData[language].opt}</div>
                            </div>
                            <div className={'col-1 row'}>
                                <div style={{display: 'block', paddingTop: '1.5rem'}}>
                                    <table>
                                        <thead>
                                        <tr>
                                            <td>
                                                <a
                                                    //className={"cursor-not-allowed"}
                                                    // href={"#"}
                                                    // href="https://instagram.com/"
                                                   // target={'_blank'}
                                                   rel="noreferrer"
                                                   title="Instagram"><img
                                                    style={{width: '2rem'}} alt={""} src={insta}/></a>
                                            </td>
                                            <td>
                                                <a className={"col-6 cursor-not-allowed"}
                                                   //href={"#"}
                                                   // href="https://instagram.com/"
                                                   rel="noreferrer"
                                                   // target={'_blank'}
                                                   title="Instagram"><img
                                                    style={{width: '2rem'}} alt={""} src={telega}/></a>
                                            </td>
                                        </tr>
                                        </thead>
                                    </table>

                                </div>
                                {/*<div style={{visibility: "hidden"}}>Links</div>*/}
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
            ;
    }

}

const mapStateToProps = ({language}) => {
    return {
        language
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        //lauguageChanged: (lang) => dispatch(lauguageChanged(lang))
    }
};

export default compose(
    withKozastoreService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Footer);

