const updateCurrentCategory = (state, action) => {
    if (state === undefined) {
        let x = parseInt(sessionStorage.getItem("currentCategory"),10);
        return !isNaN(x) ? x : -1;
    }
    switch (action.type) {
        case "SELECTED_CATEGORY_CHANGED":
            sessionStorage.setItem("currentCategory", action.payload.toString())
            return action.payload;

        default:
            return state.currentCategory;
    }
}

export default updateCurrentCategory;