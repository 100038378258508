import React from "react";

import './cart-page.css';
import {allItemsRemovedFromCart, hideCartAction, itemAddedToCart, itemRemovedFromCart} from "../../../actions";
import {connect} from "react-redux";
import ShoppingCartTable from "../../shopping-cart-table";
import {Link} from "react-router-dom";



const CartPage = ({show, items, total, language, hide, sum, onIncrease, onDecrease, onDelete}) => {
    if (!show) {
        return null;
    }
    return (
        <div className={"cart-container"}>
            <div className={"background"}></div>
            <div className={"cart"}>

                <div className={"cart-header row"}>
                    <div className={"col-4"}></div>
                    <div className={"header-text col-4"}>
                        {language === "Ua" ? "Кошик" : "Basket"}
                    </div>
                    <div
                        className={"header-button col-4"}
                        onClick={() => {
                            hide()
                        }}
                    >
                        <i className="fa-solid fa-xmark close-button"></i>
                    </div>
                </div>
                <ShoppingCartTable
                    language={language}
                    onIncrease={onIncrease}
                    total={total}
                    items={items}
                    sum={sum}
                    onDecrease={onDecrease}
                    onDelete={onDelete}
                />
                {(items?.length === 0) ? null :
                    <div className={'last-line'}>
                        <div className={"continue"}
                             onClick={() => {
                                 hide()
                             }}>
                            {(language === "Ua" ? "Продовжити покупки" : "Continue shopping")}
                        </div>
                        <div className={"buy"}>
                            <Link to={"/check-out"} reloadDocument>
                                <button className={"btn"}>
                                    {(language === "Ua" ? "ОФОРМИТИ ЗАМОВЛЕННЯ" : "BUY NOW")}
                                </button>
                            </Link>
                        </div>
                    </div>}
            </div>
        </div>);
};


const mapStateToProps = ({shoppingCart: {showCart, cartItems, orderTotal, orderSum}, language}) => {
    return {
        show: showCart,
        items: cartItems,
        total: orderTotal,
        language,
        sum: orderSum
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        hide: () => dispatch(hideCartAction()),
        onIncrease: (item)=>{dispatch(itemAddedToCart(item));},
        onDecrease: (item)=>{dispatch(itemRemovedFromCart(item));},
        onDelete: (item)=>{allItemsRemovedFromCart(item);}
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(CartPage);
