import React from 'react';

import './shopping-cart-table.css';
import defaultImg from "../../images/default_full_v5.webp";

import emptyCartImg from "../../images/default_full_v5.webp";

const apiUrl = "https://koza-style.aforehand.com.ua";
const ShoppingCartTable = ({language, items, onIncrease, onDecrease, onDelete, sum, isCheckout = false}) => {

    if (items?.length === 0) {
        return (<div
            style={{marginTop: '1rem', display: 'flex'}}
            className={'row'}>
            <div className={'col-6'} style={{
                display: 'flex',
                textAlign: "center",
                alignItems: "center",
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <div
                    style={{color: 'purple', display: 'flex'}}>
                    {(language === "Ua" ? "Ваш кошик зараз порожній" : "Your cart is empty now")}
                </div>

                <div
                    style={{color: 'orange', display: 'flex'}}>
                    {(language === "Ua" ? "Треба це виправити..." : "Fix it...")}
                </div>
            </div>
            <div className={'col-6'} style={{display: 'flex'}}>
                <img
                    style={{width: '100%'}}
                    src={emptyCartImg} alt={'empty cart'}/>
            </div>
        </div>);
    }

    const renderRow = (item, idx) => {
        const {/*id,*/ imgPrev, count, /*costKrupn,*/ total, descript} = item;
        return (
            <div key={idx}>
                <div className={"row item-row"}>
                    <div className={"col-3"}>
                        <img
                            style={{width: '100%', borderRadius: '23px'}}
                            src={(imgPrev?.length > 6) ? apiUrl + imgPrev : defaultImg} alt={"preview1"}/>
                    </div>
                    <div className={"col right-panel"}>
                        <div className={"row descript"}>
                            {descript}
                        </div>
                        <br/>
                        <div className={"row"}>

                            <button
                                onClick={() => onDecrease(item)}
                                className="btn float-right">
                                <i className="fa fa-minus"/>
                            </button>
                            <div
                                className={"count"}
                            >
                                {count}
                            </div>

                            <button
                                onClick={() => onIncrease(item)}
                                className="btn float-right">
                                <i className="fa fa-plus"/>
                            </button>

                            <button
                                onClick={() => onDelete(item)}
                                className="btn btn-delete float-right">
                                <i className="fa fa-trash-o"/>
                            </button>

                        </div>
                    </div>
                    <div className={"col-1"} style={{
                        display: 'flex',
                        alignSelf: 'center',
                        flexDirection: 'column',
                        color: "purple",
                        fontSize: '1.3rem',
                        paddingRight: '10%'
                    }}>
                        {total}&nbsp;₴
                    </div>
                </div>

            </div>
        )
    };

    // console.log("ITEMS")
    // console.log(items);


    return (
        <div className="shopping-cart-table">
            <div
                className={isCheckout ? "two-lines" : ""}
            >
                {items.map(renderRow)}
            </div>
            <div className={"line"}>

            </div>
            {
                isCheckout ?
                    <div>
                        <div className={"row item-row"} style={{marginTop:'1rem'}}>
                            <div className={"col-3"} style={{color: 'purple', paddingRight:0}}>{language === "Ua" ? "Промокод" : "Promo-code"}</div>
                            <div className={"col-9"}>
                                <input className={"form-control"}/>
                            </div>
                        </div>
                    </div>
                    : null
            }
            <div className="total">
                {sum}&nbsp;₴
            </div>
        </div>
    );
};


export default ShoppingCartTable;
