import React from "react";
import "./dropdown.css";

export default class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultSelectText: "",
            showOptionList: false,
            optionsList: []
        };
    }

    componentDidMount() {
        // Add Event Listner to handle the click that happens outside
        // the Custom Select Container
        document.addEventListener("mousedown", this.handleClickOutside);
        this.setState({
            defaultSelectText: this.props.defaultText || this.props.optionsList[0].name
        });
    }

    componentWillUnmount() {
        // Remove the event listner on component unmounting
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    // This method handles the click that happens outside the
    // select text and list area
    handleClickOutside = e => {
        if (
            !e.target.classList.contains("custom-select-option") &&
            !e.target.classList.contains("selected-text") &&
            !e.target.classList.contains("select-options") &&
            !e.target.classList.contains("search-in-options")
        ) {
            this.setState({
                showOptionList: false
            });
        }
    };

    // This method handles the display of option list
    handleListDisplay = () => {
        this.setState(prevState => {
            return {
                showOptionList: !prevState.showOptionList
            };
        });
    };

    // This method handles the setting of name in select text area
    // and list display on selection
    handleOptionClick = e => {
        let name = e.target.getAttribute("data-name");
        let id = e.target.getAttribute("data-id");
        if (id === null) {
            return;
        }
        this.setState({
            defaultSelectText: name,
            showOptionList: false
        });
        this.props.customOnChange({target: {value: name}});
    };

    render() {
        const {optionsList} = this.props;
        const {showOptionList, defaultSelectText} = this.state;
        return (
            <div className="custom-select-container">
                <div
                    className={showOptionList ? "selected-text active123" : "selected-text"}
                    onClick={this.handleListDisplay}
                >
                    {defaultSelectText}
                </div>
                {showOptionList && (
                    <ul className="select-options">
                        {optionsList.map(option => {
                            return (
                                <li
                                    className="custom-select-option liList"
                                    data-name={option.name}
                                    key={option.id}
                                    data-id={option.id}
                                    onClick={this.handleOptionClick}
                                >
                                    {option.name}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        );
    }
}