const updateCartItems = (cartItems, item, idx) => {

    if (item.count === 0) {
        return [
            ...cartItems.slice(0, idx),
            ...cartItems.slice(idx + 1)
        ];
    }

    if (idx === -1) {
        return [
            ...cartItems,
            item
        ];
    }

    return [
        ...cartItems.slice(0, idx),
        item,
        ...cartItems.slice(idx + 1)
    ];
};


const updateCartItem = (item, newItem = {}, quantity = 1) => {

    const {
        count = 0//, total = 0,
    } = newItem;

    const {
        aRapp,
        artikul,
        category,
        costKrupn,
        costMelkoe,
        descript,
        designId,
        designName,
        formId,
        formName,
        imgPrev,
        imgPrev2,
        imgPrev3,
        kolvoKrupn,
        lamin,
        nameOptPack,
        optPack,
        plotn,
        size
    } = item;

    return {
        aRapp,
        artikul,
        category,
        costKrupn,
        costMelkoe,
        descript,
        designId,
        designName,
        formId,
        formName,
        imgPrev,
        imgPrev2,
        imgPrev3,
        kolvoKrupn,
        lamin,
        nameOptPack,
        optPack,
        plotn,
        size,
        count: parseInt(count) + parseInt(quantity),
        total: (item.costKrupn * (parseInt(count) + parseInt(quantity))).toFixed(2)
    };

}

/*
const updateOrder = (state, itemId, quantity) => {
    const {productList: {items}, shoppingCart: {cartItems}} = state;
    const item = items.find(({id}) => id === itemId);
    const elemIndex = cartItems.findIndex(({id}) => id === itemId);
    const elem = cartItems[elemIndex];

    const newItem = updateCartItem(item, elem, quantity);
    return {
        orderTotal: 0,
        cartItems: updateCartItems(cartItems, newItem, elemIndex)
    };
}
*/

const updateOrder = (state, item, quantity) => {
    const {shoppingCart: {cartItems}} = state;

    const elemIndex = cartItems.findIndex((e) => e.artikul === item.artikul && e.category === item.category);
    const elem = cartItems[elemIndex];

    const newItem = updateCartItem(item, elem, quantity);
    let newCartItems = updateCartItems(cartItems, newItem, elemIndex);
    let order = {
        orderTotal: newCartItems.reduce((partialSum, a) => partialSum + a.count, 0),
        orderSum: newCartItems.reduce((partialSum, a) => partialSum + parseFloat(a.total), 0).toFixed(2),
        cartItems: newCartItems,
        showCart: state.shoppingCart.showCart
    };

    localStorage.setItem("order", JSON.stringify(order));
    return order;
}


const updateShoppingCart = (state, action) => {
    if (state === undefined) {
        let order = localStorage.getItem("order");

        return (order !== null && order !== undefined) ?
            {...JSON.parse(order), showCart: false}
            : {
                cartItems: [],
                orderTotal: 0,
                orderSum: 0,
                showCart: false
            }

    }
    switch (action.type) {
        case 'ITEM_ADDED_TO_CART':
            return updateOrder(state, action.payload, 1);

        case 'ITEM_REMOVED_FROM_CART':
            return updateOrder(state, action.payload, -1);

        case 'ALL_ITEMS_REMOVED_FROM_CART':
            const item = state.shoppingCart.cartItems.find(((e) => e.artikul === action.payload.artikul && e.category === action.payload.category));
            return updateOrder(state, action.payload, -item.count);

        case "SHOW_CART":
            return {
                cartItems: state.shoppingCart.cartItems,
                orderTotal: state.shoppingCart.orderTotal,
                orderSum: state.shoppingCart.orderSum,
                showCart: true
            }

        case "HIDE_CART":
            return {
                cartItems: state.shoppingCart.cartItems,
                orderTotal: state.shoppingCart.orderTotal,
                orderSum: state.shoppingCart.orderSum,
                showCart: false
            }

        default:
            return state.shoppingCart;
    }
}

export default updateShoppingCart;