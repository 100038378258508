import React from "react";
//Styles
import "./banner.css";

import { Link } from "react-router-dom";
import {Carousel} from "react-responsive-carousel";
// import defaultImg from "../../images/default_full_v5.webp";
//import Slider from "react-slick";



//import defaultImg from '../../images/default_full_v5.webp';


//const _baseurl = "https://koza-style.aforehand.com.ua/sliders";




/*

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <svg style={{ fill: '#800080' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" /></svg>

        </div>

    );
}





function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <svg style={{ fill: '#800080' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z" /></svg>
        </div>
    );
}

*/


/*

class CustomSlide extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            _url: "https://koza-style.com.ua"
        };



    }

    componentDidMount() {
        this.setState({ src: this.props.item.imgName });

    }
    render() {
        const { item, stateLanguage, ...props } = this.props;
        return (
            <div {...props}>
                <div className="d-block col" >
                    <img
                        key={item.imgName}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = `${this.state._url}/${defaultImg}`;
                        }}
                        src={_baseurl + "/" + item.imgName}
                        style={{ width: '70%', margin: 'auto' }}
                        alt={""}
                    />

                    <div className="carousel-caption">
                        <Link to={`/card-of-product/${item.categoryId}/${item.artikul}/${stateLanguage}`}>
                            <button type="button" className="btn  buttonStyle">Детальніше</button>
                        </Link>
                    </div>

                </div>
            </div>
        );
    }
}*/



export default class Banner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imagesList: [],
            isError: false,
            slideIndex: 1,
            isAPcreated: false,
            onPause: false
        }

    }

   /* showSlides = (n) => {
        let i;
        let slides = document.getElementsByClassName("mySlides");
        let dots = document.getElementsByClassName("dot");
        if (n > slides.length) { this.setState({ slideIndex: 1 }); }
        if (n < 1) { this.setState({ slideIndex: slides.length }); }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        if (slides[this.state.slideIndex - 1]) {
            slides[this.state.slideIndex - 1].style.display = "block";
            dots[this.state.slideIndex - 1].className += " active";
        }
    }
*/
   /* plusSlides = (n) => {
        let x = this.state.slideIndex;
        x += n;
        this.setState({ slideIndex: x });
        this.showSlides(x);
    }

    currentSlide = (n) => {
        this.setState({ slideIndex: n });
        this.showSlides(n);
    }

*/
    componentDidMount() {
        this.getData();
        //this.showSlides(1);
    }

    componentDidUpdate() {

        // if (!this.state.isAPcreated && this.state.imagesList?.length > 0) {
        //     document.getElementsByClassName('next')[0].click();
        //     window.setInterval(() => {
        //         if (!this.state.onPause) {
        //             document.getElementsByClassName('next')[0].click();
        //         }
        //     }, 7000);
        //     this.setState({ isAPcreated: true });
        // }
    }

    getData = () => {
        this.getSliderAll()
            .then((x) => {

                if (x?.resultSliderSet?.length > 0) {
                    this.setState({
                        imagesList: x.resultSliderSet
                        //resultPopup
                    });
                }
                else {
                    this.setState({
                        imagesList: [],
                        isError: true
                    });
                }
            })
    }

    getSliderAll = async () => {

        let data = {
            Type:"Rozn",
            Local:localStorage.getItem("language") ?? "En"
        };



        const response = await fetch("https://kozastyle.somee.com/api/SliderAll", {
            method: "POST",
            //mode:"cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();

    };

    render() {
       // let flagForActiveBannerImg = true;

        if (this.state.isError) { return (<React.Fragment><br /></React.Fragment>); }

        const curLanguage = localStorage.getItem("language");

        return (
            <React.Fragment>
                <div className={"main-banner-carousel"} style={{ display: this.state.imagesList.length === 0 ? 'none' : 'block', padding: 0 }}>
                    <br />

                    <Carousel
                        showThumbs={false}
                        autoPlay={true}
                        infiniteLoop={true}
                        useKeyboardArrows={true}
                        stopOnHover={true}
                        showStatus={false}
                    >
                        {
                            this.state.imagesList.map((item) => {


                                return (
                                    <div className="mySlides fade" style={{ opacity: 1 }} key={"Custorm"+(item.imgName ?? "NaN")}>
                                        <img src={"https://koza-style.aforehand.com.ua/sliders/" + item?.imgName} style={{ width: '100%', opacity: 1 }} alt={""}/>
                                        <div className="carousel-caption">
                                            {(item.artikul == null) ?

                                                <button
                                                    onClick={() => {
                                                        document.getElementById(item.categoryId).click();
                                                    }}
                                                    type="button" className="btn  buttonStyle1">
                                                    Детальніше
                                                </button>

                                                :
                                                <Link to={`/card-of-product/${item.categoryId}/${item.artikul}/${this.props.stateLanguage}`}>
                                                    <button type="button" className="btn  buttonStyle1">{curLanguage === "Ua" ? "Детальніше" : "More"}</button>
                                                </Link>
                                            }
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </Carousel>

                </div>
            </React.Fragment>
        );
    };
}



