import React, {Fragment} from "react";
import './privacy-page.css';

import Privacy from "../../privacy";

const PrivacyPage = () => {
    return(
        <Fragment>
            <Privacy/>
        </Fragment>
    );
}

export default PrivacyPage;