export default class NovaPoshtaService {

    _baseUrl = "https://api.novaposhta.ua/v2.0/json/";
    _key = "3b67ea67899238191763a18b33bce055";


    getAreas = () => {
        let data = {
            "apiKey": this._key,
            "modelName": "Address",
            "calledMethod": "getAreas",
            "methodProperties": {
                "Warehouse": "1"
            }
        };


        return fetch(this._baseUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            console.log(data);
            return data.data;
        });

    }

    getCities = () => {
        let data = {
            "apiKey": this._key,
            "modelName": "Address",
            "calledMethod": "getCities",
            "methodProperties": {
                "Warehouse": "1"
            }
        };


        return fetch(this._baseUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            console.log(data);
            return data.data;
        });

    }

getSettlements = () => {
        let data = {
            "apiKey": this._key,
            "modelName": "Address",
            //"calledMethod": "getSettlements",
            //"calledMethod": "getCities",
            "calledMethod": "getSettlementAreas",
            "methodProperties": {
                "Warehouse": "1"
            }
        };


        return fetch(this._baseUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            console.log(data);
            return data.data;
        });

    }



    getDepartmentsByCity = (city) => {


        let data = {
            "apiKey": this._key,
            "modelName": "Address",
            "calledMethod": "getWarehouses",
            "methodProperties": {
                "CityName":city
            }
        };



        return fetch(this._baseUrl, {
            method: "POST",
            //mode:"cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => {
            return response.json();
        })
            .then((data) => {
                // console.log(data);
                return data.data;
            });

    }

    getAllDepartments = () => {


        let data = {
            "apiKey": this._key,
            "modelName": "Address",
            "calledMethod": "getWarehouses",
            "methodProperties": {}
        };


        return fetch(this._baseUrl, {
            method: "POST",
            //mode:"cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => {
            return response.json();
        })
            .then((data) => {
                // console.log(data);
                return data.data;
            });

    }
}