import React, {Component, Fragment} from "react";
import './card-of-product.css';

import {compose} from "../../utils";
import {withKozastoreService} from "../hoc";
import {connect} from "react-redux";
import {fetchProductDopItems, fetchProductInfo, itemAddedToCart} from "../../actions";
import Spinner from "../spinner";
import ErrorIndicator from "../error-indicator";
import ProductListItem from "../product-list-item";

import defaultImg from '../../images/default_full_v5.webp';

import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import {useNavigate} from "react-router-dom";

//
// function SampleNextArrow(props) {
//     const {className, style, onClick} = props;
//     return (
//         <div
//             className={className}
//             style={{...style, display: "block"}}
//             onClick={onClick}
//         >
//             <svg style={{fill: '#800080'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
//                 <path
//                     d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/>
//             </svg>
//
//         </div>
//
//     );
// }
//
// function SamplePrevArrow(props) {
//     const {className, style, onClick} = props;
//     return (
//         <div
//             className={className}
//             style={{...style, display: "block"}}
//             onClick={onClick}
//         >
//             <svg style={{fill: '#800080'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
//                 <path
//                     d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z"/>
//             </svg>
//         </div>
//     );
// }
//
//
// class CustomSlide extends React.Component {
//     render() {
//         const {item, language, ...props} = this.props;
//         return (
//             <div {...props}>
//                 <div className="d-block col">
//
//                     <ProductListItem
//                         key={item.categoryId + item.artikul}
//                         item={item}
//                         onAddedToCart={() => {/*onAddedToCart(item.id)*/
//                             console.log(item.artikul, item.categoryId)
//                         }}
//                         language={language}
//                     />
//
//                 </div>
//             </div>
//         );
//     }
// }
//

class CardOfProduct extends Component {

    componentDidMount() {
        const {artikul, category} = this.props;
        this.props.fetchData(artikul, category);
        this.props.fetchDopItems(artikul, category);
    }


    render() {
        //const navigate = useNavigate();

        console.log(`----------   Card of Product   -----------`);
        console.log(this.props);
        const {data} = this.props;
        const {
            artikul,
            category,
            language,
            data: {
                // aRapp,
                costKrupn,
                // costMelkoe,
                descript,
                // designId,
                // designName,
                // formId,
                // formName,
                imgPrev,
                imgPrev2,
                imgPrev3,
                // kolvoKrupn,
                // lamin,
                // nameOptPack,
                // optPack,
                // plotn,
                // size
            },
            loading,
            error,
            dopItems,
            errorDopItems,
            loadingDopItems,
            onAddedToCart
        } = this.props;

        if (loading) {
            return (
                <div style={{minHeight: '30vh'}}>
                    <Spinner/>
                </div>
            );
        }

        if (error) {
            return <ErrorIndicator/>;
        }

        const apiUrl = "https://koza-style.aforehand.com.ua";

        let dataForCarousel = [];
        let i = 3;
        for (i = 3; i < dopItems?.length; i += 4) {
            dataForCarousel.push({e1: dopItems[i - 3], e2: dopItems[i - 2], e3: dopItems[i - 1], e4: dopItems[i]});
        }
        i -= 3;
        switch (dopItems?.length - i) {
            case 1:
                dataForCarousel.push(
                    {e1: dopItems[i], e2: null, e3: null, e4: null}
                );
                break;
            case 2:
                dataForCarousel.push(
                    {e1: dopItems[i], e2: dopItems[i + 1], e3: null, e4: null}
                );
                break;
            case 3:
                dataForCarousel.push(
                    {e1: dopItems[i], e2: dopItems[i + 1], e3: dopItems[i + 2], e4: null}
                );
                break;
            default:
                break;
        }

        const dopItemsCarousel = (
            <Fragment>
                <div className={"col-12 carousel-for-dopItems"}>
                    <Carousel
                        showThumbs={false}
                        showStatus={false}
                        infiniteLoop={true}
                        useKeyboardArrows={true}
                    >
                        {dataForCarousel.map(({e1, e2, e3, e4}) => {
                            return (
                                <Fragment key={e1.categoryId + e1.artikul + e2?.artikul + "rowInSlider"}>
                                    <div className={"row col-12"} style={{paddingLeft: '4rem', paddingRight: '2rem'}}>

                                        <ProductListItem
                                            key={e1.categoryId + e1.artikul}
                                            item={e1}
                                            onAddedToCart={() => {
                                                console.log("no func for onAddedToCart in Card-of-product")
                                            }}
                                            language={language}/>


                                        {(e2 !== null) ?

                                            <ProductListItem
                                                key={e2.categoryId + e2.artikul}
                                                item={e2}
                                                onAddedToCart={() => {
                                                    console.log("no func for onAddedToCart in Card-of-product")
                                                }}
                                                language={language}/>
                                            : null
                                        }

                                        {(e3 !== null) ?

                                            <ProductListItem
                                                key={e3.categoryId + e3.artikul}
                                                item={e3}
                                                onAddedToCart={() => {
                                                    console.log("no func for onAddedToCart in Card-of-product")
                                                }}
                                                language={language}/>

                                            : null}

                                        {(e4 !== null) ?

                                            <ProductListItem
                                                key={e4.categoryId + e4.artikul}
                                                item={e4}
                                                onAddedToCart={() => {
                                                    console.log("no func for onAddedToCart in Card-of-product")
                                                }}
                                                language={language}/>

                                            : null}

                                    </div>
                                    <div style={{height: '1rem'}}></div>
                                </Fragment>
                            );
                        })}
                    </Carousel>
                </div>
            </Fragment>);

        return (
            <Fragment>
                <div className={"card-of-product"}>
                    {/* eslint-disable-next-line no-restricted-globals */}
                    <div className={"col-1 row back-button"} onClick={() => history.back()}>
                        <i className="fa-solid fa-chevron-left"></i>&nbsp;{(language === "Ua") ? "Назад" : "Back"}
                    </div>
                    <div className={"row product-info-card-of-product"}>
                        <div className={"col-6 product-info-banner"}>
                            <Carousel
                                showThumbs={false}
                                autoPlay={true}
                                infiniteLoop={true}
                                useKeyboardArrows={true}
                                stopOnHover={true}
                            >
                                <div>
                                    <img src={(imgPrev?.length > 6) ? apiUrl + imgPrev : defaultImg} alt={"preview1"}/>
                                    {/*<p className="legend">Legend 1</p>*/}
                                </div>
                                <div>
                                    <img src={(imgPrev2?.length > 6) ? apiUrl + imgPrev : defaultImg} alt={"preview2"}/>
                                    {/*<p className="legend">Legend 1</p>*/}
                                </div>
                                <div>
                                    <img src={(imgPrev3?.length > 6) ? apiUrl + imgPrev : defaultImg} alt={"preview3"}/>
                                    {/*<p className="legend">Legend 1</p>*/}
                                </div>
                            </Carousel>
                        </div>

                        <div className={"col-6"}
                             style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <div>
                                <h4>{descript}</h4>
                                <br style={{fontSize: '2rem'}}/>
                                <div className={"row"} style={{
                                    fontSize: '2rem',
                                    color: 'purple',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <div className={"col"} style={{display: 'flex'}}>
                                        {costKrupn.toFixed(2)}₴
                                    </div>
                                    <div className={"col-5"} style={{display: 'flex'}}>
                                        <button className={"btn btn-warning"}
                                                style={{fontSize: '1rem', color: 'purple'}}
                                        onClick={()=>onAddedToCart({category, artikul,...data})}>
                                            <i className="cart-icon fa fa-shopping-cart"/>&nbsp;{(language === "Ua") ? "Додати у кошик" : "Add to basket"}
                                        </button>
                                    </div>
                                    <div className={"col"} style={{fontSize: '1rem', color: 'black', display: 'flex'}}>
                                        {(language === "Ua") ? "артикул" : "vendor code"}:&nbsp;{artikul}
                                    </div>
                                </div>
                            </div>
                            <div style={{height:'10rem'}}>

                            </div>
                            <div>
                                <h5>{(language === "Ua") ? "Опис" : "Description"}:</h5>
                                <div>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id
                                    quod mazim placerat facer possim assum.
                                </div>
                                <div>Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
                                    lobortis nisl ut aliquip ex ea commodo consequat.
                                </div>
                                <div>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie
                                    consequat, vel illum dolore eu feugiat nulla facilisis.
                                </div>
                                <div>At vero eos et accusam et justo duo dolores et ea rebum</div>
                                <div>Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                                    amet.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{color: 'purple', marginTop: '5rem', textAlign: 'center'}}>
                        {(language === "Ua") ? "Товари цієї серії" : "Product of this series/category"}
                    </div>
                    <div className={"col-12"} style={{display: 'flex', justifyContent: 'center', marginTop: '1rem'}}>
                        {
                            loadingDopItems ? <Spinner/> :
                                ((errorDopItems) ? <ErrorIndicator/> :
                                    dopItemsCarousel)
                        }
                    </div>

                </div>
            </Fragment>
        );
    }
}


const mapStateToProps = ({language, productInfo: {data, loading, error}, productDopItems}) => {
    const {dopItems} = productDopItems;
    const errorDopItems = productDopItems.error;
    const loadingDopItems = productDopItems.loading;
    return {
        language,
        data,
        loading,
        error,
        dopItems,
        errorDopItems,
        loadingDopItems
    }
};


/*
const mapDispatchToProps = (dispatch, ownProps) => {
    const {kozastoreService} = ownProps;
    return {
        fetchItems: fetchItems(kozastoreService, dispatch),
        currentCategoryChanged: (id, sortType = 1) => currentCategoryChanged(id, kozastoreService, dispatch, sortType),
        onAddedToCart: (id) => dispatch(itemAddedToCart(id))
    }
};

*/

const mapDispatchToProps = (dispatch, ownProps) => {
    const {kozastoreService} = ownProps;
    return {
        fetchData: (artikul, categoryId) => fetchProductInfo(kozastoreService, dispatch, artikul, categoryId)(),
        fetchDopItems: (artikul, categoryId) => fetchProductDopItems(kozastoreService, dispatch, artikul, categoryId)(),
        onAddedToCart: (item)=>dispatch(itemAddedToCart(item))
    }
};


export default compose(
    withKozastoreService(),
    connect(mapStateToProps, mapDispatchToProps)
)(CardOfProduct);
