import React from "react";

const {
    Provider: KozastoreServiceProvider,
    Consumer: KozastoreServiceConsumer
} = React.createContext();

export {
    KozastoreServiceProvider,
    KozastoreServiceConsumer
};
