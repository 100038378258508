const updateProductDopItems = (state, action) => {
    if (state === undefined) {
        return {
            dopItems: {},
            loading: true,
            error: null
        };
    }

    switch (action.type) {
        case 'FETCH_PRODUCT_DOP_ITEMS_REQUESTED':
            return {
                dopItems: {},
                loading: true,
                error: null
            };
        case 'FETCH_PRODUCT_DOP_ITEMS_SUCCESS':
            return {
                dopItems: action.payload,
                loading: false,
                error: null
            };
        case 'FETCH_PRODUCT_DOP_ITEMS_FAILURE':
            return {
                dopItems: {},
                loading: false,
                error: action.payload
            };
        default:
            return state.productInfo;
    }
}
export default updateProductDopItems;