const updateLanguage = (state, action) => {
    if (state === undefined) {
        let lang =localStorage.getItem("language") ?? "En";
        localStorage.setItem("language", lang);
        return lang;
    }
    switch (action.type) {
        case "LANGUAGE_CHANGED":
            return action.payload;

        default:
            return state.language;
    }
}

export default updateLanguage;