import React from "react";
import {KozastoreServiceConsumer} from "../kozastore-service-context";

const withKozastoreService = () => (Wrapped) => {
    return (props) => {
        return (
            <KozastoreServiceConsumer>
                {
                    (kozastoreService) => {
                        return (<Wrapped {...props}
                                         kozastoreService={kozastoreService}/>);
                    }
                }
            </KozastoreServiceConsumer>
        );
    }
}

export default withKozastoreService;