import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import App from './components/app';
import ErrorBoundary from "./components/error-boundary";
import KozastoreService from "./services/kozastore-service";
import {KozastoreServiceProvider} from "./components/kozastore-service-context";

import store from "./store";

const kozastoreService = new KozastoreService();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ErrorBoundary>
            <KozastoreServiceProvider value={kozastoreService}>
                <Router>
                    <App/>
                </Router>
            </KozastoreServiceProvider>
        </ErrorBoundary>
    </Provider>
);


//
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>
// );
