export default class KozastoreService {

    _baseUrl = "https://kozastyle.somee.com/api";
    _getCategories = "/getCategoriesRozn";
    _getVitrina = "/VitrinaRozn1";
    _getSortedItems = "/SortCategor";
    _getProductInfo = '/IndividualData';
    _getProductDopItems = '/DopItems';

    _postRequest = async (link,data) => {
        const response = await fetch(this._baseUrl+link, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        });
        const result = await response.json();
        return result;
    }

    async getCategories() {

        let data = {
            //"Local": "En"
            "Local": localStorage.getItem("language") ?? "En"
        };

        /*const response = await fetch(this._baseUrl+this._getCategories, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        });
        const result = await response.json();*/

        const result = await this._postRequest(this._getCategories,data);
        return result.categorU;
    };


    async getItems(lang) {
        lang = (lang.length === 2) ? lang[0].toUpperCase() + lang[1].toLowerCase() : "En";

        let data =
        {
            "Local":localStorage.getItem("language") ?? "En"
        }

        /*const response = await fetch(this._baseUrl+this._getVitrina, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        });

        const result = await response.json();*/
        const result = await this._postRequest(this._getVitrina, data);
        return result.vitrina;
    }

    async getSortedItems(lang, catId, sortType="1") {
        if(catId === -1) return await this.getItems(lang);
        lang = (lang.length === 2) ? lang[0].toUpperCase() + lang[1].toLowerCase() : "En";

        const SORT_TYPE = {
            "1": "Populars",
            "3": 'IncreaseCostKrupnoe',
            '2': 'DecreaseCostKrupnoe'
        };

        let data =
            {
                "Local":localStorage.getItem("language") ?? "En",
                "CategoryId": catId,
                "SortType": SORT_TYPE[sortType.toString()] ?? "Populars"
            }

       /* const response = await fetch(this._baseUrl+this._getSortedItems, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        });

        const result = await response.json();*/

        const result = await this._postRequest(this._getSortedItems, data);
        return result.result;
    }

    async getProductInfo(artikul, category, lang = "En"){
        lang = (lang.length === 2) ? lang[0].toUpperCase() + lang[1].toLowerCase() : "En";

        const data = {
            "Artikul": artikul,
            "CategoryId": category,
            "Local": lang
        };

        const result = await this._postRequest(this._getProductInfo, data);
        return result.itemData;
    }

    async getProductDopItems(artikul, category, lang = "En"){
        lang = (lang.length === 2) ? lang[0].toUpperCase() + lang[1].toLowerCase() : "En";

        const data = {
            "Artikul": artikul,
            "CategoryId": category,
            "Local": lang
        };

        const result = await this._postRequest(this._getProductDopItems, data);
        return result.dopAllData;
    }

}