import React from 'react';
import './shop-header.css'
import {Link} from 'react-router-dom';

import {lauguageChanged, showCartAction} from '../../actions';
import {compose} from "../../utils";

import logo from "../../images/logo_v2.png";
import Dropdown from "../dropdown/dropdown";
import {withKozastoreService} from "../hoc";
import {connect} from "react-redux";


class ShopHeader extends React.Component {
    /*constructor(props) {
        super(props);

    }*/


    render() {

        let languageList = [
            {id: 1, name: "Ua"},
            {id: 2, name: "En"}
        ];

        let {numItems, /*total,*/ language, lauguageChanged} = this.props;
        let defLang = language;

        //console.log("HEADER: " +language);


        return (
            <div style={{width: '100%', borderBottom: '3px solid orange', padding: 0}}>
                <div className={"container"} style={{padding: 0}}>

                    <header className="shop-header row">
                        <Link to='/'>
                            <div className="logo">
                                <img src={logo} alt={"Logo"}/>
                            </div>
                            <div className={"logo-text"}>настрій звичайних корисностей</div>
                        </Link>

                        <div>
                            {/*    not visible element */}
                        </div>

                        <div className={"phone"}>
                            <i className="fa fa-phone"/>&nbsp;
                            +38-050-303-56-50
                        </div>

                        <div onClick={()=>{this.props.showCartAction()}}>
                            <div className="shopping-cart row">
                                <i className="cart-icon fa fa-shopping-cart"/>
                                <div className={"numItems"}>
                                    {numItems}
                                </div>
                            </div>
                        </div>

                        {/*<Link to='/cart'>
                            <div className="shopping-cart row">
                                <i className="cart-icon fa fa-shopping-cart"/>
                                <div className={"numItems"}>
                                    {numItems}
                                </div>
                            </div>
                        </Link>*/}

                        <div className={"dropdown"}>
                            <Dropdown
                                defaultText={defLang}
                                optionsList={languageList}

                                customOnChange={
                                    (e) => {
                                        lauguageChanged(e.target.value);
                                    }}/>

                        </div>
                    </header>
                </div>
            </div>
        );
    }

}

const mapStateToProps = ({language}) => {
    return {
        language
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        lauguageChanged: (lang) => dispatch(lauguageChanged(lang)),
        showCartAction: () => dispatch(showCartAction())
    }
};

export default compose(
    withKozastoreService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ShopHeader);

