import React, {Component, Fragment} from "react";
import {connect} from "react-redux";

import ProductListItem from "../product-list-item";
import {withKozastoreService} from '../hoc';
import Spinner from "../spinner";
import ErrorIndicator from "../error-indicator";

import {currentCategoryChanged, fetchItems, itemAddedToCart} from "../../actions";
import {compose} from "../../utils";

import './product-list.css';
import Dropdown from "../dropdown/dropdown";

/*
const ProductList = ({items, onAddedToCart, language}) => {
    return (
        <React.Fragment>
            <div className="product-list row">
                {
                    items.map((item) => {
                        return (
                            <ProductListItem
                                key={item.categoryId+item.artikul}
                                item={item}
                                onAddedToCart={() => onAddedToCart(item.id)}
                                language = {language}/>

                        )
                    })
                }
            </div>
        </React.Fragment>
    );
}*/


const ProductList = ({
                         items,
                         onAddedToCart,
                         language,
                         categoryList,
                         currentCategory,
                         currentCategoryChanged,
                         sortType
                     }) => {

    if (currentCategory?.toString() === "-1") {
        let data = [1, 2, 3, 4].map((id) => {
            return {id: id, data: items.filter((o) => o.categoriPriority === id)}
        });

        return (
            <Fragment>
                {data.map((x) => {
                    return (
                        <Fragment key={"productItemsGroupFor" + x.id}>

                            <div className={"col-10 top-product-cat"}>
                                {/*{(categoryList[x.id] !== undefined ? categoryList[x.id]["categoryName"+language.toUpperCase()]:"")}*/}
                                {(language === "Ua" ? "Хіти категорії " : "Top from category ") + (categoryList[x.id] !== undefined ? categoryList[x.id]["categoryName" + language.toUpperCase()] : "")}
                            </div>
                            <div className="product-list row">
                                {
                                    x.data.map((item) => {
                                        return (
                                            <ProductListItem
                                                key={item.categoryId + item.artikul + item.categoriPriority}
                                                item={item}
                                                onAddedToCart={() => onAddedToCart(item.id)}
                                                language={language}/>

                                        )
                                    })
                                }
                            </div>
                            <div className={"col-12"}>
                                <div
                                    onClick={() => {
                                        currentCategoryChanged(x.id)
                                    }}
                                    className={"show-cat-items-link"}>{(language === "Ua" ? "Показати усі товари категорії" : "Show all items of category")}</div>
                            </div>
                            <br/>
                        </Fragment>
                    );
                })}

            </Fragment>
        );
    } else {
        let data = (language === "En") ?
            [{id: 1, name: "Popular"},
                {id: 2, name: "More expensive at first"},
                {id: 3, name: "Cheaper at first"}] :
            [{id: 1, name: "Популярні"},
                {id: 2, name: "Спочатку дорожчі"},
                {id: 3, name: "Спочатку дешевше"}];
        let defSort = (sortType !== undefined) ? data[parseInt(sortType.toString(), 10) - 1].name : ((language === "En") ? "Popular" : "Популярні");
        return (
            <Fragment>
                <div className={"container-sort-type"}>
                    <Dropdown
                        defaultText={defSort}
                        optionsList={data}

                        customOnChange={
                            (e) => {
                                let id = data.filter((i) => {
                                    return i.name === e.target.value
                                })[0].id;
                                currentCategoryChanged(currentCategory, id.toString());
                            }}/>
                </div>
                <div className="product-list row">
                    {items.map((item) => {
                        return (
                            <ProductListItem
                                key={item.categoryId + item.artikul}
                                item={item}
                                onAddedToCart={() => onAddedToCart(item.id)}
                                language={language}/>
                        )
                    })}
                </div>

            </Fragment>
        );
    }
}

class ProductListContainer extends Component {

    componentWillReceiveProps(nextProps) {
        if (nextProps.language !== this.props.language) {
            this.getOrUpdateData();
        }
    }

    getOrUpdateData() {
        if (this.props.currentCategory === -1)
            this.props.fetchItems();
        else
            this.props.currentCategoryChanged(this.props.currentCategory)
    }

    componentDidMount() {
        this.getOrUpdateData();
    }

    render() {
        const {
            items, loading, error, onAddedToCart, language,
            categoryList, currentCategory, currentCategoryChanged, sortType
        } = this.props;
        if (loading) {
            return (
                <div style={{minHeight:'30vh'}}>
                    <Spinner/>
                </div>
            );
        }

        if (error) {
            return <ErrorIndicator/>;
        }

        return <ProductList items={items}
                            onAddedToCart={onAddedToCart}
                            language={language}

                            categoryList={categoryList}
                            currentCategory={currentCategory}
                            currentCategoryChanged={currentCategoryChanged}
                            sortType={sortType}/>;
    }
}


const mapStateToProps = ({
                             productList: {items, loading, error},
                             language,
                             currentCategory,
                             sortType,
                             categories: {categoryList}
                         }) => {
    return {
        items,
        loading,
        error,
        language,
        categoryList,
        currentCategory,
        sortType
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const {kozastoreService} = ownProps;
    return {
        fetchItems: fetchItems(kozastoreService, dispatch),
        currentCategoryChanged: (id, sortType = 1) => currentCategoryChanged(id, kozastoreService, dispatch, sortType),
        onAddedToCart: (id) => dispatch(itemAddedToCart(id))
    }
};

export default compose(
    withKozastoreService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ProductListContainer);

// const mapDispatchToProps = (dispatch) => {
//     return bindActionCreators({
//         itemsLoaded
//     }, dispatch);
// }
// export default withKozastoreService()(
//     connect(mapStateToProps, mapDispatchToProps)(ProductList)
// );