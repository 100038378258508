import React, {Component} from "react";
import {compose} from "../../utils";
import {withKozastoreService} from "../hoc";
import {connect} from "react-redux";
import Radio from "../radio";
import {currentCategoryChanged, fetchCategories} from "../../actions";
import Spinner from "../spinner";
import ErrorIndicator from "../error-indicator";

class CategoryList extends Component {


    componentDidMount() {
        this.props.getCategorise();
        //this.setState({lang: this.props.language});
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.language !== this.props.language) {
            this.props.getCategorise();
        }
    }

   /* componentDidUpdate() {
        console.log("-------CategoryList---------");
        console.log(this.props);
    }*/

    render() {


        let {currentCategory, language, categoryList, loading, error, currentCategoryChanged} = this.props;

        if (loading) {
            return <Spinner/>;
        }

        if (error) {
            return <ErrorIndicator/>;
        }

        return (

            <div>

                <div style={{color:"purple", padding:0, margin:0}}>{language === "Ua" ? "Категорії" : "Categories"}:</div>
                {categoryList.length > 0 ?
                    <Radio
                        key={"CatItem -1"}
                        text={language === "Ua" ? "Усі категоріі" : "All categories"}
                        onChange={(e) => {
                            currentCategoryChanged(-1);
                        }}
                        selected={currentCategory.toString()}
                        // selected={(currentCategory.toString()===c.categoryId.toString()).toString()}
                        value={"-1"}/>
                    :
                    null}

                {
                    categoryList.length > 0 ?
                        categoryList.map((c) => {
                            return (
                                <Radio
                                    key={"CatItem" + language + (c?.id) + Math.random().toString()}
                                    text={c["categoryName" + language?.toUpperCase()] ?? ""}
                                    onChange={(e) => {
                                        currentCategoryChanged(e);
                                    }}
                                    selected={currentCategory?.toString()}
                                    // selected={(currentCategory.toString()===c.categoryId.toString()).toString()}
                                    value={c.id?.toString()}/>
                            );
                        })
                        :
                        <Spinner/>
                }

            </div>

        );
    }
}


const mapStateToProps = ({categories: {categoryList, loading, error}, currentCategory, language}) => {
    return {
        currentCategory,
        language,
        categoryList,
        loading,
        error
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const {kozastoreService} = ownProps;
    return {
        getCategorise: fetchCategories(kozastoreService, dispatch),
        currentCategoryChanged: (id) => currentCategoryChanged(id, kozastoreService,dispatch)
    }
};


export default compose(
    withKozastoreService(),
    connect(mapStateToProps, mapDispatchToProps)
)(CategoryList);
