import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";

import {withKozastoreService} from '../hoc';
import ShopHeader from "../shop-header";
import {HomePage, CartPage, PrivacyPage, CardOfProductPage} from "../pages";

import './app.css';
import Footer from "../footer";
import ErrorIndicator from "../error-indicator";
import {compose} from "../../utils";
import {connect} from "react-redux";
import CheckOutPage from "../pages/check-out/check-out";

// import ShoppingCartTable from "../shopping-cart-table";

const App = ({orderTotal}) => {
    return (
        <main role={"main"}>
            <ShopHeader numItems={orderTotal}/>
            <CartPage/>
            <div className={"container"} style={{minHeight:`70vh`}}>
                <Routes>
                    <Route
                        path="/"
                        element={<HomePage/>}
                    />
                    {/*<Route
                        path="/cart"
                        element={<CartPage/>}
                    />*/}

                    <Route
                        path="/check-out"
                        element={<CheckOutPage/>}
                    />

                    <Route
                        path="/privacy"
                        element={<PrivacyPage/>}
                    />
                    <Route
                        path="/card-of-product/:category/:artikul"
                        element={<CardOfProductPage/>}
                    />

                    <Route
                        path="/error"
                        element={<ErrorIndicator/>}
                    />

                    <Route
                        path="*"
                        element={<Navigate replace to="/error"/>}
                    />
                </Routes>
            </div>
            <Footer/>
        </main>
    );
};


const mapStateToProps = ({language, shoppingCart:{orderTotal}}) => {

    return {
        language,
        orderTotal
    }
};
const mapDispatchToProps = (dispatch, ownProps) => {
    //const {kozastoreService} = ownProps;
    return {

    }
};


export default compose(
    withKozastoreService(),
    connect(mapStateToProps, mapDispatchToProps)
)(App);


//export default withKozastoreService()(App);