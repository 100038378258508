const sha1 = require('js-sha1');

export default class PaymentService {

    _liqPayKeys = {
        publicKey: "sandbox_i7348565839",
        privateKey: "sandbox_TTRuVzKR0vDWgso2nR5WqCNYd37ZjIF5WfzFqVbn"
    }

    _liqPayApi = "https://www.liqpay.ua/api/3/checkout";

    _callBackLink = "https://webhook.site/3acabf40-0f85-4b69-aab6-2809658ebfe1";


    payForOrderWithLiq = (amount = 1) => {
        let currentdate = new Date();
        let orderId = "RoznOrder" + currentdate.getDate() + "/"
            + (currentdate.getMonth() + 1) + "/"
            + currentdate.getFullYear() + " @ "
            + currentdate.getHours() + ":"
            + currentdate.getMinutes() + ":"
            + currentdate.getSeconds();


        /*

        let data = btoa(JSON.stringify({
            "action": "pay",
            "amount": "7.98",
            "currency": "UAH",
            "description": "test-payment",
            "order_id": "RoznOrder23/4/2024 @ 16:40:41",
            "public_key": "sandbox_i7348565839",
            "version": "3"
        }));*/
        let data = btoa(JSON.stringify({
            "public_key": this._liqPayKeys.publicKey,
            "version": "3",
            "action": "pay",
            "amount": amount,
            "currency":"UAH",
            "description":"test-payment",
            "order_id": orderId
        }));

        let sign_string = this._liqPayKeys.privateKey + data + this._liqPayKeys.privateKey;
        let hexString = (sha1(sign_string));
        const signature = btoa(hexString.match(/\w{2}/g).map(a => String.fromCharCode(parseInt(a, 16))).join(""));





        // return fetch(this._liqPayApi, {
        //     method: "POST",
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(data)
        // }).then((response) => {
        //     return response.json();
        // }).then((data) => {
        //     console.log(data);
        //     return data.data;
        // });

        let res = {data: data, signature: signature, x: JSON.parse(atob(data))};
        console.log(res)
        return res;

    }

}