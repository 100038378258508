import updateProductList from "./product-list";
import updateShoppingCart from "./shopping-cart";
import updateLanguage from "./language";
import updateCurrentCategory from "./category";
import updateCategoryLists from "./category-lists";
import updateSortType from "./sort-type";
import updateProductInfo from "./product-info";
import updateProductDopItems from "./product-dop-items";



const reducer = (state, action) => {
    return {
        productList: updateProductList(state, action),
        shoppingCart: updateShoppingCart(state, action),
        language: updateLanguage(state,action),
        currentCategory: updateCurrentCategory(state,action),
        sortType: updateSortType(state,action),
        categories: updateCategoryLists(state,action),

        productInfo: updateProductInfo(state,action),
        productDopItems: updateProductDopItems(state, action)
    }
}


export default reducer;