import React from "react";

import './home-page.css';

import ProductList from "../../product-list";
import Banner from "../../banner/banner";


import CategoryList from "../../category-list";

const HomePage = () => {
    return (
        <div>
            <div className={"row"}>
                <div className={"col-2"}>
                    <CategoryList />
                </div>
                <div className={"col-10"}>
                    <Banner/>
                    <ProductList/>
                </div>
            </div>
        </div>
    );
};

export default HomePage;