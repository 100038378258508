import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";


import './check-out.css';
import ShoppingCartTable from "../../shopping-cart-table";
import {allItemsRemovedFromCart, itemAddedToCart, itemRemovedFromCart} from "../../../actions";
import Radio from "../../radio";
import NovaPoshtaService from "../../../services/novaPoshta-service";
import Dropdown from "../../dropdown/dropdown";
import PaymentService from "../../../services/payment-service";


class CheckOutPage extends Component {


    state = {
        email: "",
        phone: "",
        firstName: "",
        lastName: "",
        isClientReciever: false,
        delivery: "",
        payment: null,
        reciever: {
            firstName: "",
            lastName: "",
            phone: ""
        },
        departments: [],


        areasList: [],
        citiesList: [],
        departmentsList: [],


        selectedArea: '',
        selectedCity: '',
        selectedNPSettelment: '',
        selectedNP: "",
        NPFilter: ''

    }

    novaPost = new NovaPoshtaService();
    paymentService = new PaymentService();

    componentDidMount() {
        //this.getSettlements();
        this.getAreas();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(this.state);
    }

    getAreas = async () => {
        var x = await this.novaPost.getAreas();
        let i = 0;
        this.setState({
            areasList: x.map((el) => {
                return ({
                    id: i++,
                    name: `${el.Description}`,
                    obj: el
                });
            })
        });
    }

    getCities = async (city) => {
        var x = await this.novaPost.getCities();

        let i = 0;
        this.setState({
            citiesList: x.filter((x) => x.AreaDescription.includes(city))
                .map((el) => {
                    return ({
                        id: i++,
                        name: `${el.Description}`,
                        obj: el
                    });
                })
        });
    }

    getSettlements = async () => {
        var x = await this.novaPost.getSettlements();
        //await this.setState({settelmentsList: x});
        let i = 0;
        this.setState({
            settelmentsList: x.map((el) => {
                return ({
                    id: i++,
                    name: `${el.Description}, ${el.AreaDescription}`,
                    obj: el
                });
            })
        });
    }

    getAllDepartmentsByCity = async (city) => {
        var x = await this.novaPost.getDepartmentsByCity(city);
        this.setState({departments: x});
        console.log(x);
        let i = 0;
        this.setState({
            departmentsList: x.map((el) => {
                return ({
                    id: i++,
                    name: `${el.Description}`
                });
            })
        });
    }

    deliveryOnChange = (e) => {
        if (this.state.delivery === e) {
            this.setState({delivery: ""});
        } else {
            this.setState({delivery: e});
        }
        // console.log(e);
    }


    generateDataForLiqPay =()=>{
        return this.paymentService.payForOrderWithLiq(this.props.sum).data;
    }
    generateSignatureForLiqPay =()=>{
        return this.paymentService.payForOrderWithLiq(this.props.sum).signature;
    }


    render() {
        const {items, language, sum, onIncrease, total, onDecrease, onDelete} = this.props;
        if (items?.length === 0) {
            return (<Fragment>
                ПУСТАЯ КОРЗИНА, что-то должно тут быть...
            </Fragment>);
        }


        const block2Info = [
            {
                key: "Nova1",
                value: "NovaPoshta",
                text: language === "Ua" ? "Самовивіз з Нової Пошти" : "???"
            },
            {
                key: "Nova2",
                value: "NovaPoshta2",
                text: language === "Ua" ? "Кур'єр НовоїПошти на Вашу адресу" : "???"
            },
            {
                key: "Meest1",
                value: "Meest1",
                text: language === "Ua" ? "Самовивіз з Meest" : "???"
            },
            {
                key: "Meest2",
                value: "Meest2",
                text: language === "Ua" ? "Кур'єр Meest на Вашу адресу" : "???"
            },
            {
                key: "UkrPost1",
                value: "UkrPost1",
                text: language === "Ua" ? "Самовивіз з Укрпошти" : "???"
            }

        ];
        // text={language === "Ua" ? "Самовивіз з Нової" : "???"}
        // onChange={(e) => {
        //     this.deliveryOnChange(e);
        // }}
        // selected={this.state.delivery.toString()}
        // // selected={(currentCategory.toString()===c.categoryId.toString()).toString()}
        // value={"NovaPoshta"}
        // textClasses={"purple-text"}

        let elemInput = <input className={"form-control search-in-options"}
                               value={this.state.NPFilter}
                               onChange={(e) => {
                                   this.setState({NPFilter: e.target.value})
                               }
                               }/>
        let searchDep = {id: null, name: elemInput};

        return (
            <Fragment>
                <div className={"check-out-container"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <h3 className={"purple-text"}>{(language === "Ua") ? "Оформлення замовника" : "Check out"}</h3>
                        </div>
                    </div>

                    <div className={'main-form'}>
                        <div className={"row"}>
                            <div className={"col-6 left-block"}>
                                <div className={"block-1"}>
                                    <div className={"box-header row"}>
                                        <div className="number purple-text">1</div>
                                        <div
                                            className={"purple-text header-text"}>{language === "Ua" ? "Контактні дані замовника" : "Conact data ???"}</div>
                                    </div>
                                    <div className="form-group lastName">
                                        <label htmlFor="lastNameInput"
                                               className="form-label mt-4 purple-text">{language === "Ua" ? "Призвіще" : "Last name"}<sup>*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lastNameInput"
                                            aria-describedby="lastNameHelp"
                                            placeholder={(language === "Ua") ? "Призвіще" : "Last name"}
                                            value={this.state.lastName}
                                            onChange={(e) => {
                                                this.setState({lastName: e.target.value})
                                            }}
                                        />
                                    </div>
                                    <div className="form-group firstName">
                                        <label htmlFor="firstNameInput"
                                               className="form-label mt-4 purple-text">{language === "Ua" ? "Ім'я" : "First name"}<sup>*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="firstNameInput"
                                            aria-describedby="firstNameHelp"
                                            placeholder={language === "Ua" ? "Ім'я" : "First name"}
                                            value={this.state.firstName}
                                            onChange={(e) => {
                                                this.setState({firstName: e.target.value})
                                            }}
                                        />
                                    </div>
                                    <div className="form-group phone">
                                        <label htmlFor="phoneInput"
                                               className="form-label mt-4 purple-text">{language === "Ua" ? "Мобільний телефон" : "Phone number"}<sup>*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phoneInput"
                                            aria-describedby="phoneHelp"
                                            placeholder="+38 (0XX) XX-XXX-XX"
                                            value={this.state.phone}
                                            onChange={(e) => {
                                                this.setState({phone: e.target.value})
                                            }}
                                        />
                                    </div>
                                    <div className="form-group email">
                                        <label htmlFor="emailInput"
                                               className="form-label mt-4 purple-text">E-mail<sup>*</sup></label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="emailInput"
                                            aria-describedby="emailHelp"
                                            placeholder="E-mail"
                                            value={this.state.email}
                                            onChange={(e) => {
                                                this.setState({email: e.target.value})
                                            }}
                                        />
                                        <small id="emailHelp" className="form-text text-muted">We'll never share your
                                            email
                                            with
                                            anyone else.</small>
                                    </div>
                                    <div className="form-group">

                                        <Radio
                                            text={language === "Ua" ? "Замовник є отримувачем" : "???"}
                                            onChange={(e) => {
                                                this.setState({isClientReciever: !this.state.isClientReciever});
                                            }}
                                            selected={this.state.isClientReciever.toString()}
                                            // selected={(currentCategory.toString()===c.categoryId.toString()).toString()}
                                            value={"true"}
                                            textClasses={"purple-text"}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className={"col-6 right-block"}>
                                <div className={"shopping-cart"}>
                                    <ShoppingCartTable
                                        language={language}
                                        onIncrease={onIncrease}
                                        total={total}
                                        items={items}
                                        sum={sum}
                                        onDecrease={onDecrease}
                                        onDelete={onDelete}
                                        isCheckout={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-6 left-block"}>
                                <div className={"block-2"}>
                                    <div className={"box-header row"} style={{marginBottom: '0.5rem'}}>
                                        <div className="number purple-text">2</div>
                                        <div
                                            className={"purple-text header-text"}>{language === "Ua" ? "Доставка" : "Delivery ???"}</div>
                                    </div>

                                    <div className="form-group">

                                        {
                                            block2Info.map((x) => {
                                                return (
                                                    <Radio
                                                        key={x.key}
                                                        text={x.text}
                                                        onChange={(e) => {
                                                            this.deliveryOnChange(e);
                                                        }}
                                                        selected={this.state.delivery.toString()}
                                                        value={x.value}
                                                        textClasses={"purple-text"}
                                                    />
                                                )
                                            })
                                        }

                                    </div>
                                    <br/>
                                </div>
                            </div>
                            <div className={"col-6  right-block"}>
                                {this.state.delivery === "NovaPoshta" ?
                                    <div className={"col-12 delivery"}>
                                        <div className="form-group">
                                            <Dropdown
                                                defaultText={language === "Ua" ? "Область" : "State"}
                                                optionsList={this.state.areasList}
                                                value={this.state.selectedArea}
                                                customOnChange={
                                                    (e) => {
                                                        this.setState({selectedArea: e.target.value})
                                                        this.getCities(e.target.value);
                                                    }}/>
                                        </div>


                                        {
                                            this.state.selectedArea === '' ? null :
                                                <div className="form-group">
                                                    <Dropdown
                                                        defaultText={language === "Ua" ? "Місто" : "City"}
                                                        optionsList={this.state.citiesList}
                                                        value={this.state.selectedCity}
                                                        customOnChange={
                                                            (e) => {
                                                                this.setState({selectedCity: e.target.value})
                                                                this.getAllDepartmentsByCity(e.target.value)
                                                            }}/>
                                                </div>

                                        }

                                        {
                                            this.state.selectedCity === '' ? null :
                                                <div className="form-group">
                                                    <Dropdown
                                                        defaultText={language === "Ua" ? "Відділення" : "Department"}
                                                        optionsList={[searchDep, ...this.state.departmentsList.filter((x)=>x.name.toLowerCase()
                                                            .includes(this.state.NPFilter.toLowerCase()))]}
                                                        value={this.state.selectedNP}
                                                        customOnChange={
                                                            (e) => {
                                                                this.setState({selectedNP: e.target.value})
                                                            }}/>
                                                </div>

                                        }
                                    </div> : null}

                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"left-block"}>
                                <div className={"block-3"}>
                                    <div className={"box-header row"}>
                                        <div className="number purple-text">3</div>
                                        <div
                                            className={"purple-text header-text"}>{language === "Ua" ? "Оплата" : "Payment ???"}</div>
                                    </div>
                                    <br/>
                                    <div className="col-12">
                                        <Radio
                                            text={"LiqPay"}
                                            onChange={(e) => {
                                                this.setState({payment:'LiqPay'});
                                            }}
                                            selected={(this.state.payment==='LiqPay').toString()}
                                            // selected={(currentCategory.toString()===c.categoryId.toString()).toString()}
                                            value={"true"}
                                            textClasses={"purple-text"}
                                        />
                                        <Radio
                                            text={"Privat24"}
                                            onChange={(e) => {
                                                this.setState({payment: "Privat"});
                                            }}
                                            selected={(this.state.payment==='Privat').toString()}
                                            // selected={(currentCategory.toString()===c.categoryId.toString()).toString()}
                                            value={"true"}
                                            textClasses={"purple-text"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*
                        <form method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8" >
                            <input type="hidden" name="data"
                                   value="eyJhY3Rpb24iOiJwYXkiLCJhbW91bnQiOiI3Ljk4IiwiY3VycmVuY3kiOiJVQUgiLCJkZXNjcmlwdGlvbiI6InRlc3QtcGF5bWVudCIsIm9yZGVyX2lkIjoiUm96bk9yZGVyMjMvNC8yMDI0IEAgMTY6MTg6MzAiLCJwdWJsaWNfa2V5Ijoic2FuZGJveF9pNzM0ODU2NTgzOSIsInZlcnNpb24iOiIzIn0="/>
                            <input type="hidden" name="signature" value={"mnIEB2GYGLrYsdCU4/llkCCmx50="}/>
                            <input type={"hidden"} name={"server_url"} value={"https://webhook.site/3acabf40-0f85-4b69-aab6-2809658ebfe1"}/>
                            <input type="image" src="//static.liqpay.ua/buttons/payUk.png" alt={language==="Ua" ?"Сплатити": "pay"}/>
                        </form>*/}

                        {/*<form method="POST" acceptCharset="utf-8" onSubmit={(e)=>{console.log(this.paymentService.payForOrderWithLiq(sum));e.preventDefault()}}>*/}
                        <form method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8" >
                            <input type="hidden" name="data"
                                   value={this.generateDataForLiqPay()}/>
                            <input type="hidden" name="signature" value={this.generateSignatureForLiqPay()}/>
                            <input type={"hidden"} name={"server_url"} value={"https://webhook.site/3acabf40-0f85-4b69-aab6-2809658ebfe1"}/>
                            <input type="image" src="//static.liqpay.ua/buttons/payUk.png" alt={language==="Ua" ?"Сплатити": "pay"}/>
                        </form>

                        {this.state.isClientReciever ?
                            null :
                            <div className={"row"}>
                                <div className={"left-block"}>
                                    <div className={"block-4"}>
                                        <div className={"box-header row"}>
                                            <div className="number purple-text">4</div>
                                            <div
                                                className={"purple-text header-text"}>{language === "Ua" ? "Контактні дані отримувача замовлення" : "???"}</div>
                                        </div>
                                        <div className="form-group lastNameDelivery">
                                            <label htmlFor="lastNameDeliveryInput"
                                                   className="form-label mt-4 purple-text">{language === "Ua" ? "Призвіще" : "Last name"}<sup>*</sup></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="lastNameDeliveryInput"
                                                aria-describedby="lastNameDeliveryHelp"
                                                placeholder={(language === "Ua") ? "Призвіще" : "Last name"}
                                                value={this.state.reciever.lastName}
                                                onChange={(e) => {
                                                    let x = this.state.reciever;
                                                    x.lastName = e.target.value;
                                                    this.setState({reciever: x});
                                                }}
                                            />
                                        </div>
                                        <div className="form-group firstNameDelivery">
                                            <label htmlFor="firstNameDeliveryInput"
                                                   className="form-label mt-4 purple-text">{language === "Ua" ? "Ім'я" : "First name"}<sup>*</sup></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="firstNameDeliveryInput"
                                                aria-describedby="firstNameDeliveryHelp"
                                                placeholder={language === "Ua" ? "Ім'я" : "First name"}
                                                value={this.state.reciever.firstName}
                                                onChange={(e) => {
                                                    let x = this.state.reciever;
                                                    x.firstName = e.target.value;
                                                    this.setState({reciever: x});
                                                }}
                                            />
                                        </div>
                                        <div className="form-group phone">
                                            <label htmlFor="phoneDeliveryInput"
                                                   className="form-label mt-4 purple-text">{language === "Ua" ? "Мобільний телефон" : "Phone number"}<sup>*</sup></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="phoneDeliveryInput"
                                                aria-describedby="phoneDeliveryHelp"
                                                placeholder="+38 (0XX) XX-XXX-XX"
                                                value={this.state.reciever.phone}
                                                onChange={(e) => {
                                                    let x = this.state.reciever;
                                                    x.phone = e.target.value;
                                                    this.setState({reciever: x});
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>}


                    </div>

                </div>
            </Fragment>
        )
            ;
    }
    ;
}


const mapStateToProps = ({shoppingCart: {cartItems, orderSum}, language}) => {
    return {
        items: cartItems,
        language,
        sum: orderSum
    }
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onIncrease: (item) => {
            dispatch(itemAddedToCart(item));
        },
        onDecrease: (item) => {
            dispatch(itemRemovedFromCart(item));
        },
        onDelete: (item) => {
            allItemsRemovedFromCart(item);
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutPage);