const updateSortType = (state, action) => {
    if (state === undefined) {
        let x = parseInt(sessionStorage.getItem("sortType"),10);
        return !isNaN(x) ? x : 1;
    }
    switch (action.type) {
        case "SELECTED_SORT_TYPE_CHANGED":
            sessionStorage.setItem("sortType", action.payload.toString())
            return action.payload.toString();

        default:
            return state.sortType;
    }
}

export default updateSortType;