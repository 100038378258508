const itemsLoaded = (newItems) => {
    return {
        type: 'FETCH_ITEMS_SUCCESS',
        payload: newItems
    }
};

const itemsRequested = () => {
    return {
        type: 'FETCH_ITEMS_REQUESTED'
    };
};

const itemsError = (error) => {
    return {
        type: 'FETCH_ITEMS_FAILURE',
        payload: error
    }
};


export const itemAddedToCart = (item) => {
    return {
        type: 'ITEM_ADDED_TO_CART',
        payload: item
    }
}


export const itemRemovedFromCart = (item) => {
    return {
        type: 'ITEM_REMOVED_FROM_CART',
        payload: item
    }
}

export const allItemsRemovedFromCart = (item) => {
    return {
        type: 'ALL_ITEMS_REMOVED_FROM_CART',
        payload: item
    }
}


const fetchItems = (storeService, dispatch) => () => {
    dispatch(itemsRequested());
    storeService.getItems(localStorage.getItem("language") ?? "En")
        .then((data) => dispatch(itemsLoaded(data)))
        .catch((err) => dispatch(itemsError(err)));
}


const categoriseLoaded = (newCat) => {
    return {
        type: 'FETCH_CATEGORIES_SUCCESS',
        payload: newCat
    }
};

const categoriesRequested = () => {
    return {
        type: 'FETCH_CATEGORIES_REQUESTED'
    };
};

const categoriesError = (error) => {
    return {
        type: 'FETCH_CATEGORIES_FAILURE',
        payload: error
    }
};


const fetchCategories = (storeService, dispatch) => () => {
    dispatch(categoriesRequested());
    storeService.getCategories(localStorage.getItem("language") ?? "En")
        .then((data) => dispatch(categoriseLoaded(data)))
        .catch((err) => dispatch(categoriesError(err)));
}

const lauguageChanged = (lang) => {
    return {
        type: "LANGUAGE_CHANGED",
        payload: lang
    }
}

const currentCategoryChangedAction = (id) => {
    return {
        type: "SELECTED_CATEGORY_CHANGED",
        payload: id
    }
}

const currentSortTypeChangedAction = (id) => {
    return {
        type: "SELECTED_SORT_TYPE_CHANGED",
        payload: id
    }
}

const currentCategoryChanged = (id, storeService, dispatch, sortType = "1") => {
    dispatch(currentCategoryChangedAction(id));
    dispatch(currentSortTypeChangedAction(sortType));
    dispatch(itemsRequested());
    storeService.getSortedItems(localStorage.getItem("language") ?? "En", id, sortType)
        .then((data) => dispatch(itemsLoaded(data)))
        .catch((err) => dispatch(itemsError(err)));
    fetchItems(storeService, dispatch, id);
}

const fetchProductInfo = (storeService, dispatch, artikul, category) => () => {
    dispatch(productInfoRequested());
    storeService.getProductInfo(artikul, category, localStorage.getItem("language") ?? "En")
        .then((data) => dispatch(productInfoLoaded(data)))
        .catch((err) => dispatch(productInfoError(err)));
}


const productInfoRequested = () => {
    return {
        type: 'FETCH_PRODUCT_INFO_REQUESTED'
    };
};

const productInfoLoaded = (newData) => {
    return {
        type: 'FETCH_PRODUCT_INFO_SUCCESS',
        payload: newData
    }
};

const productInfoError = (error) => {
    return {
        type: 'FETCH_PRODUCT_INFO_FAILURE',
        payload: error
    }
};


const fetchProductDopItems = (storeService, dispatch, artikul, category) => () => {
    dispatch(productDopItemsRequested());
    storeService.getProductDopItems(artikul, category, localStorage.getItem("language") ?? "En")
        .then((data) => dispatch(productDopItemsLoaded(data)))
        .catch((err) => dispatch(productDopItemsError(err)));
}

const productDopItemsRequested = () => {
    return {
        type: 'FETCH_PRODUCT_DOP_ITEMS_REQUESTED'
    };
};

const productDopItemsLoaded = (newData) => {
    return {
        type: 'FETCH_PRODUCT_DOP_ITEMS_SUCCESS',
        payload: newData
    }
};

const productDopItemsError = (error) => {
    return {
        type: 'FETCH_PRODUCT_DOP_ITEMS_FAILURE',
        payload: error
    }
};


export const showCartAction = () => {
    return{
        type: "SHOW_CART"
    }
}

export const hideCartAction = () => {
    return{
        type: "HIDE_CART"
    }
}

export {
    fetchItems,
    fetchCategories,
    lauguageChanged,
    currentCategoryChanged,
    fetchProductInfo,
    fetchProductDopItems
};