import {createStore, applyMiddleware} from 'redux';

import reducer from "./reducers";

const logMiddleware = (store) => (dispatch) => (action) => {
    console.log("-------------store---------------");
    console.log(store.getState());
    console.log("-------------action--------------");
    console.log(action);
    console.log("---------------------------------");
    return dispatch(action);
};

const saveLanguageMiddleware =  (store) => (dispatch) => (action) => {
    if(action.type === "LANGUAGE_CHANGED") {
        localStorage.setItem("language", action.payload);
    }
    return dispatch(action);
};

const store = createStore(reducer, applyMiddleware(
    logMiddleware,
    saveLanguageMiddleware)
);

export default store;