import PropTypes from "prop-types";
import React, { Component } from "react";
import "./radio.css";

export default class Radio extends Component {
    state = {};

    render() {
        const { selected, onChange, text, value,id, textClasses } = this.props;
        return (
            <div
                id={id}
                className="modern-radio-container"
                onClick={() => {
                    onChange(value);
                }}
            >
                <div
                    className={`radio-outer-circle ${value !== selected && "unselected"}`}
                >
                    <div
                        className={`radio-inner-circle ${value !== selected &&
                        "unselected-circle"}`}
                    />
                </div>
                <div className={"helper-text "+textClasses}>{text}</div>
            </div>
        );
    }
}

Radio.propTypes = {
    text: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};
