import React, {Fragment} from "react";

import './card-of-product-page.css';
import {useParams} from "react-router-dom";
import CardOfProduct from "../../card-of-product/card-of-product";

const CardOfProductPage = () => {
    let {artikul,category}=useParams();

    return(
        <Fragment>
            <CardOfProduct artikul = {artikul} category = {category}/>
        </Fragment>
    );

};

export default CardOfProductPage;