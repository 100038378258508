const updateProductList = (state, action) => {
    if (state === undefined) {
        return {
            items: [],
            loading: true,
            error: null
        };
    }

    switch (action.type) {
        case 'FETCH_ITEMS_REQUESTED':
            return {
                items: [],
                loading: true,
                error: null
            };
        case 'FETCH_ITEMS_SUCCESS':
            return {
                items: action.payload,
                loading: false,
                error: null
            };
        case 'FETCH_ITEMS_FAILURE':
            return {
                items: [],
                loading: false,
                error: action.payload
            };
        default:
            return state.productList;
    }
}
export default updateProductList;