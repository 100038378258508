import { React } from 'react';
import './spinner.css';

const Spinner = () => {
    return (
        <div className='parent'>
            <div className="loadingio-spinner-pulse-s6x2jzg27kl "><div className="ldio-r5t29llr5oj">
                <div></div><div></div><div></div>
            </div></div>
        </div>
    );
};

export default Spinner;