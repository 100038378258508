import React, {Fragment} from "react";
//import {Link} from "react-router-dom";

// import defaultImg from '../../images/default_pre_v4.webp';
import defaultImg from '../../images/default_pre_v5.webp';
import './product-list-item.css';
import {Link} from "react-router-dom";


const isImgPathValid = (imgPrew) => {
    return (imgPrew === null || imgPrew===undefined || imgPrew.length === 0 || imgPrew.toLowerCase() === "/pre");
}

class ProductListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item:{costKrupnoe:0,imgPrew:"", descript:""}
        }
    }

    componentDidMount() {
        this.setState({item:this.props.item})
    }

    render() {
        const {/*onAddedToCart,*/ language} = this.props;
        const apiUrl = "https://koza-style.aforehand.com.ua";
        const {costKrupnoe, imgPrew, descript, artikul,categoryId} = this.state.item;

        return (
            <Fragment>
                <Link reloadDocument to={`/card-of-product/${categoryId}/${artikul}`} className="product-list-item col-3" >
                {/*<div className="product-list-item col-3">*/}
                    <div className="product-cover">
                        <img src={isImgPathValid(imgPrew) ? defaultImg : apiUrl + imgPrew} alt={language === "En" ? "cover" : "фото товару"}
                             onError={(e) => {
                                 // imgPrew = null;
                                 /*console.log(`\n\n${"!!!!!!!!!!!!!!!"}${e}`);
                                 console.log(e);*/
                                 this.setState({item:{...(this.props.item), imgPrew:undefined}});
                             }}/>
                    </div>
                    <div className="product-details">
                        <span className="product-title">{descript}</span>
                        <div className="product-price">{costKrupnoe.toFixed(2)} ₴</div>
                        {/*<button
                            onClick={onAddedToCart}
                            className="btn btn-info add-to-cart">
                            {language === "Ua" ? "У кошик" : "Add to cart"}
                        </button>*/}
                    </div>
                {/*</div>*/}
                </Link>
            </Fragment>


        );
    };
};

//
// const ProductListItem = ({item, onAddedToCart, language}) => {
//     const apiUrl = "https://koza-style.aforehand.com.ua";
//     const {costKrupnoe, imgPrew, descript} = item;
//
//
//     return (
//         <Fragment>
//             <div className="product-list-item col-3">
//                 <div className="product-cover">
//                     <img src={isImgPathValid(imgPrew) ? defaultImg : apiUrl+imgPrew} alt={"cover"}
//                         />
//                 </div>
//                 <div className="product-details">
//                     <span className="product-title">{descript}</span>
//                     <div className="product-price">{costKrupnoe} {language === "Ua" ? "грн." : "uah"}</div>
//                     <button
//                         onClick={onAddedToCart}
//                         className="btn btn-info add-to-cart">
//                         {language === "UA" ? "У кошик" : "Add to cart"}
//                     </button>
//                 </div>
//             </div>
//         </Fragment>
//
//
//     );
// };


export default ProductListItem;