const updateProductInfo = (state, action) => {
    if (state === undefined) {
        return {
            data: {},
            loading: true,
            error: null
        };
    }

    switch (action.type) {
        case 'FETCH_PRODUCT_INFO_REQUESTED':
            return {
                data: {},
                loading: true,
                error: null
            };
        case 'FETCH_PRODUCT_INFO_SUCCESS':
            return {
                data: action.payload,
                loading: false,
                error: null
            };
        case 'FETCH_PRODUCT_INFO_FAILURE':
            return {
                data: {},
                loading: false,
                error: action.payload
            };
        default:
            return state.productInfo;
    }
}
export default updateProductInfo;